import React from 'react';
import cx from 'classnames';

import './input-search.scss';

const BLOCK = 'ui_input-search';

interface InputSearchProps {
    className?: string;
    value: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    placeholder?: string;
}

export const InputSearch: React.FunctionComponent<InputSearchProps> = ({ className, placeholder, value, onChange }) => {
    return (
        <input
            className={cx(BLOCK, className)}
            type="search"
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            autoComplete="false"
        />
    );
};
