import React from 'react';
import { useParams } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-bootstrap/Carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

import { Header } from '../../components/Header';
import { Footer } from '../../components/Footer';
import { BannerJoinUs } from '../../components/BannerJoinUs';
import { Flex } from '../../components/ui/Flex';
import { RatingStars } from '../../components/ui/RatingStars';

import './boba-tea-page.scss';

const BLOCK = 'boba-tea-page';

interface BobaTeaPageProps {}

export const BobaTeaPage: React.FunctionComponent<BobaTeaPageProps> = () => {
    const { name }: { [key: string]: string } = useParams();

    console.log('[wtb] name', name)

    const place = {
        name,
        likes: 4554,
        views: 12331,
        comments_count: 23,
        address: '21 rue de chateaudun 75009',
        rating: 4.3,
        description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur faucibus nibh eget erat imperdiet semper. Fusce orci mauris, condimentum et vehicula sed, finibus interdum elit. Nulla ac tincidunt dolor.<br />Nulla semper magna lectus, id euismod tellus posuere et. Fusce a ultrices nulla. Curabitur euismod dolor vel dui dignissim euismod. In ac tincidunt ante. Nam pharetra, sapien ac tincidunt finibus, justo ligula blandit enim, ut dignissim ipsum dui id magna. Donec justo metus, pellentesque sed consectetur ut, imperdiet eu augue. Ut tristique, eros id efficitur finibus, orci tellus viverra ex, non iaculis turpis lacus eget purus. Nulla ac tellus in lacus fermentum vestibulum eget quis elit. Nulla mollis elit massa, ac sollicitudin enim fringilla eget. In vel justo sagittis, vestibulum libero ac, cursus elit. Suspendisse potenti. Ut pulvinar nunc quis tortor dapibus lacinia. Quisque faucibus sit amet velit a euismod. Morbi eget arcu sed augue tincidunt maximus et ac ante. Nam eget porttitor libero. Curabitur lorem orci, semper sit amet laoreet et, posuere a nunc. Donec eu felis non dolor mollis dapibus vitae eu ex. Nulla ac lacinia lectus. Sed malesuada vehicula quam, eget bibendum sem ultricies id. Praesent dictum turpis non bibendum aliquet.',
        favourite: 'Brown Sugar',
        favouriteImg: 'george-zheng-8h6L01Httpw-unsplash_medium.jpg'
    };

    const integerRating = parseInt(String(place.rating)) * 100;
    const percentageToShow = place.rating * 100 - parseFloat(String(integerRating));

    return (
        <>
            <Header />
            <Container className={BLOCK}>
                <Row>
                    <Col>
                        <Flex justify="center" alignItems="center" direction="column">
                            <div className={`${BLOCK}__title`}>
                                <h1>{place.name}</h1>
                            </div>
                            <div className={`${BLOCK}__small-informations`}>
                                <Flex isFullWidth alignItems="center" justify="center">
                                    <Flex className={`${BLOCK}__small-informations__item`} alignItems="center">
                                        <img src={`${process.env.PUBLIC_URL}/icons/eye.svg`} alt="eye" />
                                        {place.views}
                                    </Flex>
                                    <Flex className={`${BLOCK}__small-informations__item`} alignItems="center">
                                        <img src={`${process.env.PUBLIC_URL}/icons/heart.svg`} alt="heart" />
                                        {place.likes}
                                    </Flex>
                                    <Flex className={`${BLOCK}__small-informations__item`} alignItems="center">
                                        <img src={`${process.env.PUBLIC_URL}/icons/comment.svg`} alt="comment" />
                                        {place.comments_count}
                                    </Flex>
                                </Flex>
                            </div>
                        </Flex>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className={`${BLOCK}__carousel`}>
                            <Carousel>
                                <Carousel.Item
                                    className={`${BLOCK}__carousel__item`}
                                    style={{
                                        backgroundImage: `url(${process.env.PUBLIC_URL}/boba-tea-img/andre-benz-MsMISAIe8Qw-unsplash.jpg`
                                    }}
                                />
                                <Carousel.Item
                                    className={`${BLOCK}__carousel__item`}
                                    style={{
                                        backgroundImage: `url(${process.env.PUBLIC_URL}/boba-tea-img/erik-eastman-wJj5Bs1jHxg-unsplash.jpg`
                                    }}
                                />
                                <Carousel.Item
                                    className={`${BLOCK}__carousel__item`}
                                    style={{
                                        backgroundImage: `url(${process.env.PUBLIC_URL}/boba-tea-img/masaaki-komori-qwPSnBvdhtI-unsplash.jpg`
                                    }}
                                />
                            </Carousel>
                        </div>
                    </Col>
                </Row>
                <Row className="justify-content-md-center">
                    <Col md="9">
                        <div className={`${BLOCK}__rating`}>
                            <h3>Rating</h3>
                            <Flex justify="center" className={`${BLOCK}__rating__content`}>
                                <RatingStars rating={place.rating} />
                            </Flex>
                        </div>
                        <div className={`${BLOCK}__description`}>
                            <h3>Description</h3>
                            <div className={`${BLOCK}__informations`}>
                                <p>Address: {place.address}</p>
                            </div>
                            <div dangerouslySetInnerHTML={{ __html: place.description }} />
                        </div>
                        <div className={`${BLOCK}__what-do-we-like`}>
                            <h3>Our favorite</h3>
                            <Row>
                                <Col
                                    md={4}
                                    xs={12}
                                    className={`${BLOCK}__what-do-we-like__img`}
                                    style={{
                                        backgroundImage: `url(${process.env.PUBLIC_URL}/boba-tea-img/${place.favouriteImg}`
                                    }}
                                ></Col>
                                <Col md={8} xs={12} className={`${BLOCK}__what-do-we-like__description`}>
                                    <div className={`${BLOCK}__what-do-we-like__description__content`}>
                                        <h3>Brown Sugar Boba Milk</h3>
                                        <p>
                                            This brown sugar is so good! Especially the boba, there is something special
                                            about them, there is kind of a popping sugar mix with the brown sugar which
                                            adds something unique!
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className={`${BLOCK}__access`}>
                            <h3>Where is it?</h3>
                            <p>Address: {place.address}</p>
                            <p>You can access to this place:</p>
                            <ul>
                                <li>Subway: 7 and 9, station Chausse d'antin lafayette</li>
                                <li>Bus: 123, station Chausse d'antin lafayette</li>
                            </ul>
                        </div>
                        <div className={`${BLOCK}__feedback`}>
                            <h3>You feedback are welcome!</h3>
                            <p>Please contact us at hello@wheretoboba.com</p>
                        </div>
                    </Col>
                </Row>
            </Container>
            <BannerJoinUs />
            <Footer />
        </>
    );
};
