import React from 'react';
import cx from 'classnames';

import './menu-list.scss';

const BLOCK = 'menu-list__item';

interface MenuListItemProps {
    className?: string;
}

export const MenuListItem: React.FunctionComponent<MenuListItemProps> = ({ className, children }) => {
    return <li className={cx(BLOCK, className)}>{children}</li>;
};
