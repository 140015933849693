import React from 'react';
import { Link } from 'react-router-dom';
// import cx from 'classnames';

import { Flex } from '../../../../components/ui/Flex';
// import { List, ListItem } from '../../../../components/ui/List';
import { Button } from '../../../../components/ui/Button';

import './home-page-latest-discoveries.scss';

const BLOCK = 'home-page-latest-discoveries';

const LATEST_DISCOVERIES = [
    {
        id: '123132',
        background: '/top-boba-tea-places/roman-bozhko-OXXsAafHDeo-unsplash.jpg',
        name: 'Hello world!',
        views: 1000,
        likes: 1231,
        comments_count: 233,
        description:
            'Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Fusce porttitor metus eget lectus consequat, sit amet feugiat magna vulputate. Phasellus',
        area: 'Paris 12'
    },
    {
        id: '123132asd',
        background: '/top-boba-tea-places/petr-sevcovic-qE1jxYXiwOA-unsplash.jpg',
        name: 'Machi Machi!',
        views: 32300,
        likes: 1231,
        comments_count: 233,
        description:
            'Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Fusce porttitor metus eget lectus consequat, sit amet feugiat magna vulputate. Phasellus',
        area: 'Paris 12'
    },
    {
        id: '123132zcxcz',
        background: 'top-boba-tea-places/note-thanun-g5zKscwrdMM-unsplash.jpg',
        name: 'Xin fu Tang 1!',
        views: 1233230,
        likes: 122331,
        comments_count: 2133,
        description:
            'Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Fusce porttitor metus eget lectus consequat, sit amet feugiat magna vulputate. Phasellus',
        area: 'Paris 12'
    },
    {
        id: '12313211233',
        background: '/top-boba-tea-places/desti-nursinta-8cSW5u5U3x4-unsplash.jpg',
        name: 'Hello world!',
        views: 23134,
        likes: 2319,
        comments_count: 332,
        description:
            'Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Fusce porttitor metus eget lectus consequat, sit amet feugiat magna vulputate. Phasellus',
        area: 'Paris 12'
    },
    {
        id: '31f0231',
        background: '/top-boba-tea-places/clay-banks-hwLAI5lRhdM-unsplash.jpg',
        name: 'EWAxcz!',
        views: 23134,
        likes: 2319,
        comments_count: 332,
        description:
            'Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Fusce porttitor metus eget lectus consequat, sit amet feugiat magna vulputate. Phasellus',
        area: 'Paris 12'
    },
    {
        id: '31f0231',
        background: '/top-boba-tea-places/the-creative-exchange-d1ngW7SNehM-unsplash.jpg',
        name: 'ASd asdasd!',
        views: 23134,
        likes: 2319,
        comments_count: 332,
        description:
            'Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Fusce porttitor metus eget lectus consequat, sit amet feugiat magna vulputate. Phasellus',
        area: 'Paris 12'
    }
];

export const HomePageLatestDiscoveries: React.FunctionComponent<{}> = ({}) => {
    return (
        <section className={BLOCK}>
            {/* <Flex isFullWidth> */}
            {/* <Flex className={`${BLOCK}__sidebar`} direction="column">
                    <h2>Hello!</h2>
                    <p>
                        We are the wheretoboba team and we try to give you guys the best content and all the new boba
                        tea places around the town!
                    </p>
                    <h2>Filter by</h2>
                    <List>
                        <ListItem>Paris 1</ListItem>
                        <ListItem>Paris 2</ListItem>
                        <ListItem>Paris 3</ListItem>
                        <ListItem>Paris 4</ListItem>
                        <ListItem>Paris 5</ListItem>
                        <ListItem>Paris 6</ListItem>
                        <ListItem>Paris 7</ListItem>
                        <ListItem>Paris 8</ListItem>
                        <ListItem>Paris 9</ListItem>
                        <ListItem>Paris 10</ListItem>
                        <ListItem>Paris 11</ListItem>
                        <ListItem>Paris 12</ListItem>
                        <ListItem>Paris 13</ListItem>
                        <ListItem>Paris 14</ListItem>
                        <ListItem>Paris 15</ListItem>
                        <ListItem>Paris 16</ListItem>
                        <ListItem>Paris 17</ListItem>
                        <ListItem>Paris 18</ListItem>
                        <ListItem>Paris 19</ListItem>
                        <ListItem>Paris 20</ListItem>
                    </List>
                </Flex> */}
            <h2>Latest Discoveries</h2>
            <Flex className={`${BLOCK}__boba-tea-list`} direction="column" isFullWidth>
                <Flex className={`${BLOCK}__grid`}>
                    {LATEST_DISCOVERIES.map((place) => {
                        return (
                            <div className={`${BLOCK}__grid__item`}>
                                <div
                                    className={`${BLOCK}__grid__item__img`}
                                    style={{ backgroundImage: `url(${process.env.PUBLIC_URL}${place.background})` }}
                                />
                                <Flex className={`${BLOCK}__grid__item__informations`} direction="column">
                                    <Flex className={`${BLOCK}__grid__item__informations__title`}>{place.name}</Flex>
                                    <Flex
                                        className={`${BLOCK}__grid__item__informations__categories`}
                                        alignItems="center"
                                    >
                                        <Flex alignItems="center">
                                            <img src={`${process.env.PUBLIC_URL}/icons/eye.svg`} alt="eye" />
                                            {place.views}
                                        </Flex>
                                        <Flex alignItems="center">
                                            <img src={`${process.env.PUBLIC_URL}/icons/heart.svg`} alt="like" />
                                            {place.likes}
                                        </Flex>
                                        <Flex alignItems="center">
                                            <img src={`${process.env.PUBLIC_URL}/icons/comment.svg`} alt="comment" />
                                            {place.comments_count}
                                        </Flex>
                                        &nbsp;/&nbsp;<span>{place.area}</span>
                                    </Flex>
                                    <Flex className={`${BLOCK}__grid__item__informations__description`}>
                                        {place.description}
                                    </Flex>
                                    <Flex className={`${BLOCK}__grid__item__informations__actions`}>
                                        <Link to={`/boba-tea/${place.id}`}>
                                            <Button label="Read more" />
                                        </Link>
                                    </Flex>
                                </Flex>
                            </div>
                        );
                    })}
                </Flex>
            </Flex>
            {/* </Flex> */}
        </section>
    );
};
