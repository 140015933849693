import React from 'react';
import { useParams } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-bootstrap/Carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

import { Header } from '../../components/Header';
import { Footer } from '../../components/Footer';
import { BannerJoinUs } from '../../components/BannerJoinUs';
import { Flex } from '../../components/ui/Flex';
import { RatingStars } from '../../components/ui/RatingStars';
import { Button } from '../../components/ui/Button';

import './top-places-page.scss';

const BLOCK = 'top-places-page';

interface BobaTeaPlace {
    id: string;
    background: string;
    name: string;
    views: number;
    likes: number;
    comments_count: number;
    rating: number;
    area: string;
}

const TOP_BOBA_TEA_PLACES: BobaTeaPlace[] = [
    {
        id: '123132',
        background: '/top-boba-tea-places/roman-bozhko-OXXsAafHDeo-unsplash.jpg',
        name: 'Hello world!',
        views: 1000,
        likes: 1231,
        comments_count: 233,
        rating: 4.3,
        area: 'Paris 12'
    },
    {
        id: '123132asd',
        background: '/top-boba-tea-places/petr-sevcovic-qE1jxYXiwOA-unsplash.jpg',
        name: 'Machi Machi!',
        views: 32300,
        likes: 1231,
        comments_count: 233,
        rating: 4.3,
        area: 'Paris 12'
    },
    {
        id: '123132zcxcz',
        background: 'top-boba-tea-places/note-thanun-g5zKscwrdMM-unsplash.jpg',
        name: 'Xin fu Tang 1!',
        views: 1233230,
        likes: 122331,
        comments_count: 2133,
        rating: 4.3,
        area: 'Paris 12'
    },
    {
        id: '12313211233',
        background: '/top-boba-tea-places/desti-nursinta-8cSW5u5U3x4-unsplash.jpg',
        name: 'Hello world!',
        views: 23134,
        likes: 2319,
        comments_count: 332,
        rating: 4.3,
        area: 'Paris 12'
    },
    {
        id: '31f0231',
        background: '/top-boba-tea-places/clay-banks-hwLAI5lRhdM-unsplash.jpg',
        name: 'Hello world!',
        views: 23134,
        likes: 2319,
        comments_count: 332,
        rating: 4.3,
        area: 'Paris 12'
    },
    {
        id: '31f0231',
        background: '/top-boba-tea-places/clay-banks-hwLAI5lRhdM-unsplash.jpg',
        name: 'Hello world!',
        views: 23134,
        likes: 2319,
        comments_count: 332,
        rating: 4.3,
        area: 'Paris 12'
    },
    {
        id: '31f0231',
        background: '/top-boba-tea-places/clay-banks-hwLAI5lRhdM-unsplash.jpg',
        name: 'Hello world!',
        views: 23134,
        likes: 2319,
        comments_count: 332,
        rating: 4.3,
        area: 'Paris 12'
    },
    {
        id: '31f0231',
        background: '/top-boba-tea-places/clay-banks-hwLAI5lRhdM-unsplash.jpg',
        name: 'Hello world!',
        views: 23134,
        likes: 2319,
        comments_count: 332,
        rating: 4.3,
        area: 'Paris 12'
    },
    {
        id: '31f0231',
        background: '/top-boba-tea-places/clay-banks-hwLAI5lRhdM-unsplash.jpg',
        name: 'Hello world!',
        views: 23134,
        likes: 2319,
        comments_count: 332,
        rating: 4.3,
        area: 'Paris 12'
    },
    {
        id: '31f0231',
        background: '/top-boba-tea-places/clay-banks-hwLAI5lRhdM-unsplash.jpg',
        name: 'Hello world!',
        views: 23134,
        likes: 2319,
        comments_count: 332,
        rating: 4.3,
        area: 'Paris 12'
    }
];

interface TopPlacesPageProps {}

export const TopPlacesPage: React.FunctionComponent<TopPlacesPageProps> = () => {
    return (
        <>
            <Header />
            <Container className={BLOCK}>
                <Row>
                    <Col xs={12} md={12}>
                        <h1>Top Boba Tea Places</h1>
                    </Col>
                </Row>
            </Container>
            <Container className={BLOCK}>
                <Row>
                    {/* <Col xs={12} md={9} className={`${BLOCK}__places`}> */}
                    <Col xs={12} md={12} className={`${BLOCK}__places`}>
                        {TOP_BOBA_TEA_PLACES.map((place) => (
                            <Row className={`${BLOCK}__place`}>
                                <Col xs={12} md={6}>
                                    <Carousel>
                                        <Carousel.Item
                                            className={`${BLOCK}__place__img`}
                                            style={{
                                                backgroundImage: `url(${process.env.PUBLIC_URL}/boba-tea-img/andre-benz-MsMISAIe8Qw-unsplash.jpg`
                                            }}
                                        />
                                        <Carousel.Item
                                            className={`${BLOCK}__place__img`}
                                            style={{
                                                backgroundImage: `url(${process.env.PUBLIC_URL}/boba-tea-img/erik-eastman-wJj5Bs1jHxg-unsplash.jpg`
                                            }}
                                        />
                                        <Carousel.Item
                                            className={`${BLOCK}__place__img`}
                                            style={{
                                                backgroundImage: `url(${process.env.PUBLIC_URL}/boba-tea-img/masaaki-komori-qwPSnBvdhtI-unsplash.jpg`
                                            }}
                                        />
                                    </Carousel>
                                </Col>
                                <Col xs={12} md={6} className={`${BLOCK}__place__informations`}>
                                    <h3>{place.name}</h3>
                                    <Flex className={`${BLOCK}__place__social-media`} alignItems="center">
                                        <Flex alignItems="center">
                                            <img src={`${process.env.PUBLIC_URL}/icons/eye.svg`} alt="eye" />
                                            {place.views}
                                        </Flex>
                                        <Flex alignItems="center">
                                            <img src={`${process.env.PUBLIC_URL}/icons/heart.svg`} alt="heart" />
                                            {place.likes}
                                        </Flex>
                                        <Flex alignItems="center">
                                            <img src={`${process.env.PUBLIC_URL}/icons/comment.svg`} alt="comment" />
                                            {place.comments_count}
                                        </Flex>
                                        &nbsp;/&nbsp;<span>{place.area}</span>
                                    </Flex>
                                    <p>
                                        <RatingStars rating={place.rating} />
                                    </p>
                                    <p>
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum sapiente veniam
                                        culpa nobis consectetur! Neque animi necessitatibus, mollitia soluta voluptate
                                        quod, porro facere, quasi amet odit repudiandae dolore cumque aperiam.
                                    </p>
                                    <Flex justify="flex-end" isFullWidth>
                                        <Button level="primary" label="See more" />
                                    </Flex>
                                </Col>
                            </Row>
                        ))}
                    </Col>
                    {/* <Col xs={12} md={3}>
                        <h2></h2>
                    </Col> */}
                </Row>
            </Container>
            <BannerJoinUs />
            <Footer />
        </>
    );
};
