import React from 'react';
import cx from 'classnames';

import './list.scss';

const BLOCK = 'ui_list__item';

interface ListProps {
    className?: string;
}

export const ListItem: React.FunctionComponent<ListProps> = ({ className, children }) => {
    return <li className={cx(BLOCK, className)}>{children}</li>;
};
