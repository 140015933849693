import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import { MenuList, MenuListItem } from '../MenuList';
import { Flex } from '../ui/Flex';
import { InputSearch } from '../ui/InputSearch';
import { Logo } from '../ui/Logo';

import {
    HOME_PAGE_URL,
    ALL_PLACES_PAGE_URL,
    TOP_PLACES_URL,
    NEW_PLACES_URL,
    MAP_PAGE_URL,
    BLOG_URL,
    ORDER_URL
} from '../../routes-constants';

import './header.scss';

const BLOCK = 'header';

export const Header: React.FunctionComponent<{}> = () => {
    const [searchValue, setSearchValue] = React.useState<string>('');

    return (
        <div className={BLOCK}>
            <Container className={`${BLOCK}__container`}>
                <Row>
                    <Flex isFullWidth={true} isFullHeight={true} alignItems="center" justify="center">
                        <MenuList>
                            <MenuListItem className={`${BLOCK}__logo`}>
                                <Link to={HOME_PAGE_URL}>
                                    <Logo />
                                </Link>
                            </MenuListItem>
                            <MenuListItem>
                                <NavLink exact activeClassName={`${BLOCK}--active`} to={HOME_PAGE_URL}>
                                    Home
                                </NavLink>
                            </MenuListItem>
                            <MenuListItem>
                                <NavLink exact activeClassName={`${BLOCK}--active`} to={ALL_PLACES_PAGE_URL}>
                                    Boba Tea Places
                                </NavLink>
                            </MenuListItem>
                            <MenuListItem>
                                <NavLink exact activeClassName={`${BLOCK}--active`} to={TOP_PLACES_URL}>
                                    Top
                                </NavLink>
                            </MenuListItem>
                            <MenuListItem>
                                <NavLink exact activeClassName={`${BLOCK}--active`} to={NEW_PLACES_URL}>
                                    New
                                </NavLink>
                            </MenuListItem>
                            {/* <MenuListItem>
                                <NavLink exact activeClassName={`${BLOCK}--active`} to={MAP_PAGE_URL}>
                                    Map
                                </NavLink>
                            </MenuListItem>
                            <MenuListItem>
                                <NavLink exact activeClassName={`${BLOCK}--active`} to={BLOG_URL}>
                                    Blog
                                </NavLink>
                            </MenuListItem>
                            <MenuListItem>
                                <NavLink exact activeClassName={`${BLOCK}--active`} to={ORDER_URL}>
                                    Order
                                </NavLink>
                            </MenuListItem> */}
                            <MenuListItem>
                                <InputSearch
                                    className={`${BLOCK}__search-input`}
                                    placeholder="Search"
                                    value={searchValue}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        setSearchValue(e.target.value)
                                    }
                                />
                            </MenuListItem>
                        </MenuList>
                    </Flex>
                </Row>
            </Container>
        </div>
    );
};
