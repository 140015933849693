import React from 'react';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faSnapchat, faTwitter } from '@fortawesome/free-brands-svg-icons';

// import { Button } from '../ui/Button';
import { Flex } from '../ui/Flex';
import { List, ListItem } from '../ui/List';

import './footer.scss';

const BLOCK = 'footer';

export const Footer: React.FunctionComponent<{}> = () => {
    return (
        <div className={BLOCK}>
            <Container>
                <Row>
                    <Col>LOGO</Col>
                    <Col>
                        <div className={`${BLOCK}__item`}>
                            <div className={`${BLOCK}__item__title`}>Contact</div>
                            <div className={`${BLOCK}__item__informations`}>
                                <List>
                                    <ListItem>+33 1 23 43 54 11</ListItem>
                                    <ListItem>contact@wheretoboba.com</ListItem>
                                </List>
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <div className={`${BLOCK}__item`}>
                            <div className={`${BLOCK}__item__title`}>Boba Tea Places</div>
                            <div className={`${BLOCK}__item__informations`}>
                                <List>
                                    <ListItem>Register a Boba Tea Place</ListItem>
                                    <ListItem>Highlight a Boba Tea Place</ListItem>
                                </List>
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <div className={`${BLOCK}__item`}>
                            <div className={`${BLOCK}__item__title`}>About</div>
                            <div className={`${BLOCK}__item__informations`}>
                                <List>
                                    <ListItem>
                                        <Link to="/about-us">
                                            Who are we?
                                        </Link>
                                    </ListItem>
                                    <ListItem>Becoming a tester</ListItem>
                                </List>
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <div className={`${BLOCK}__item`}>
                            <div className={`${BLOCK}__item__title`}>Social network</div>
                            <Flex className={`${BLOCK}__item__informations`}>
                                <FontAwesomeIcon icon={faInstagram} size="lg" />
                                <FontAwesomeIcon icon={faSnapchat} size="lg" />
                                <FontAwesomeIcon icon={faTwitter} size="lg" />
                            </Flex>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container className="mt-2">
                <Row>
                    <Flex justify="center" isFullWidth>
                        @2021 WhereToBoba - All right reserved
                    </Flex>
                </Row>
            </Container>
        </div>
    );
};
