import React from 'react';
import cx from 'classnames';

import './button.scss';

const BLOCK = 'ui_button';

interface ButtonProps {
    label: string;
    isNaked?: boolean;
    level?: 'primary' | 'secondary';
    size?: 'sm' | 'md' | 'lg';
    onChange?: (e: React.MouseEvent) => void;
    className?: string;
}

export const Button: React.FunctionComponent<ButtonProps> = ({
    label,
    size = 'md',
    level = 'primary',
    isNaked = false,
    className
}) => {
    return (
        <button
            type="button"
            className={cx(
                BLOCK,
                {
                    [`${BLOCK}__${size}`]: size,
                    [`${BLOCK}__${level}`]: level,
                    [`${BLOCK}__${isNaked}`]: isNaked
                },
                className
            )}
        >
            {label}
        </button>
    );
};
