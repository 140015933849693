import React from 'react';
import { BrowserRouter as Router, Switch, Route, useLocation } from 'react-router-dom';

// import { Header } from './components/Header';
import { HomePage } from './pages/home-page';
import { ComingSoonPage } from './pages/coming-soon';
import { AllPlacesPage } from './pages/all-places-page';
import { TopPlacesPage } from './pages/top-places-page';
import { NewPlacesPage } from './pages/new-places-page';
import { MapPage } from './pages/map-page';
import { BlogPage } from './pages/blog-page';
import { OrderPage } from './pages/order-page';
import { BobaTeaPage } from './pages/boba-tea-page';
import { AboutUs } from './pages/about-us';
import {
    HOME_PAGE_URL,
    ALL_PLACES_PAGE_URL,
    TOP_PLACES_URL,
    NEW_PLACES_URL,
    MAP_PAGE_URL,
    BLOG_URL,
    ORDER_URL,
    BOBA_TEA_PLACE_URL,
    ABOUT_US_URL
} from './routes-constants';

import './App.scss';

const ScrollToTop: React.FunctionComponent<{}> = () => {
    const { pathname } = useLocation();
  
    React.useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
  
    return null;
  }

const App = () => {
    return (
        <Router>
            <ScrollToTop />
            <div className="App">
                <Switch>
                    <Route exact path={HOME_PAGE_URL}>
                        <ComingSoonPage />
                    </Route>
                    <Route path="/v1">
                        <HomePage />
                    </Route>
                    <Route path={ALL_PLACES_PAGE_URL}>
                        <AllPlacesPage />
                    </Route>
                    <Route path={TOP_PLACES_URL}>
                        <TopPlacesPage />
                    </Route>
                    <Route path={NEW_PLACES_URL}>
                        <NewPlacesPage />
                    </Route>
                    <Route path={MAP_PAGE_URL}>
                        <MapPage />
                    </Route>
                    <Route path={BLOG_URL}>
                        <BlogPage />
                    </Route>
                    <Route path={ORDER_URL}>
                        <OrderPage />
                    </Route>
                    <Route path={BOBA_TEA_PLACE_URL} children={<BobaTeaPage />} />
                    <Route path={ABOUT_US_URL}>
                        <AboutUs />
                    </Route>
                </Switch>
            </div>
        </Router>
    );
};

export default App;
