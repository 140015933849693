import React from 'react';

import './menu-list.scss';

const BLOCK = 'menu-list';

interface MenuListProps {}

export const MenuList: React.FunctionComponent<MenuListProps> = ({ children }) => {
    return <ul className={BLOCK}>{children}</ul>;
};
