import React from 'react';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

import { Flex } from '../Flex';

import './rating-stars.scss';

const BLOCK = 'ui_rating-stars';

interface RatingStarsProps {
    className?: string;
    rating: number;
}

export const RatingStars: React.FunctionComponent<RatingStarsProps> = ({ className, rating }) => {
    return (
        <Flex className={cx(BLOCK, className)} alignItems="center">
            <span>{rating}</span>
            <div className="star-rating" title={`${(rating / 5) * 100}%`}>
                <div className="back-stars">
                    <FontAwesomeIcon icon={faStar} size="lg" />
                    <FontAwesomeIcon icon={faStar} size="lg" />
                    <FontAwesomeIcon icon={faStar} size="lg" />
                    <FontAwesomeIcon icon={faStar} size="lg" />
                    <FontAwesomeIcon icon={faStar} size="lg" />

                    <div className="front-stars" style={{ width: `${(rating / 5) * 100}%` }}>
                        <FontAwesomeIcon icon={faStar} size="lg" />
                        <FontAwesomeIcon icon={faStar} size="lg" />
                        <FontAwesomeIcon icon={faStar} size="lg" />
                        <FontAwesomeIcon icon={faStar} size="lg" />
                        <FontAwesomeIcon icon={faStar} size="lg" />
                    </div>
                </div>
            </div>
        </Flex>
    );
};
