import React from 'react';
import cx from 'classnames';

import './flex.scss';

const BLOCK = 'ui_flex';

interface FlexProps {
    className?: string;
    direction?: 'row' | 'column';
    alignItems?: 'flex-start' | 'center' | 'flex-end' | 'stretch' | 'baseline';
    wrap?: 'nowrap' | 'wrap' | 'wrap-reverse';
    justify?: 'flex-start' | 'center' | 'flex-end' | 'space-between' | 'space-around' | 'space-evenly';
    isFullWidth?: boolean;
    isFullHeight?: boolean;
    isInline?: boolean;
}

export const Flex: React.FunctionComponent<FlexProps> = ({
    className,
    direction = 'row',
    alignItems = 'flex-start',
    justify = 'flex-start',
    wrap = 'wrap',
    isFullHeight = false,
    isFullWidth = false,
    isInline,
    children,
}) => {
    return (
        <div
            className={cx(
                BLOCK,
                `${BLOCK}--direction-${direction}`,
                `${BLOCK}--align-items-${alignItems}`,
                `${BLOCK}--justify-${justify}`,
                `${BLOCK}--wrap-${wrap}`,
                {
                    [`${BLOCK}--is-full-width`]: isFullWidth,
                    [`${BLOCK}--is-full-height`]: isFullHeight,
                    [`${BLOCK}--is-inline`]: isInline,
                },
                className
            )}
        >
            {children}
        </div>
    );
};
