import React from 'react';
import Container from 'react-bootstrap/Container';

import './order-page.scss';

const BLOCK = 'order-page';

export const OrderPage: React.FunctionComponent<{}> = () => {
    return (
        <div className={BLOCK}>
            <Container>
                Hello, I'm OrderPage
                <span>:D</span>
            </Container>
        </div>
    );
};
