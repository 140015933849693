import React from 'react';
import cx from 'classnames';

import './list.scss';

const BLOCK = 'ui_list';

interface ListProps {
    className?: string;
    style?: 'none' | 'disc' | 'circle';
}

export const List: React.FunctionComponent<ListProps> = ({ className, style = 'none', children }) => {
    return (
        <ul
            className={cx(BLOCK, className, {
                [`${BLOCK}__list-style--${style}`]: style,
            })}
        >
            {children}
        </ul>
    );
};
