import React from 'react';
import { Link } from 'react-router-dom';

import { Flex } from '../../../../components/ui/Flex';

import './home-page-top-boba-tea-places.scss';

const BLOCK = 'home-page-top-boba-tea-places';

interface BobaTeaPlace {
    id: string;
    background: string;
    name: string;
    views: number;
    likes: number;
    comments_count: number;
    area: string;
}

const TOP_BOBA_TEA_PLACES: BobaTeaPlace[] = [
    {
        id: '123132',
        background: '/top-boba-tea-places/roman-bozhko-OXXsAafHDeo-unsplash.jpg',
        name: 'Hello world!',
        views: 1000,
        likes: 1231,
        comments_count: 233,
        area: 'Paris 12'
    },
    {
        id: '123132asd',
        background: '/top-boba-tea-places/petr-sevcovic-qE1jxYXiwOA-unsplash.jpg',
        name: 'Machi Machi!',
        views: 32300,
        likes: 1231,
        comments_count: 233,
        area: 'Paris 12'
    },
    {
        id: '123132zcxcz',
        background: 'top-boba-tea-places/note-thanun-g5zKscwrdMM-unsplash.jpg',
        name: 'Xin fu Tang 1!',
        views: 1233230,
        likes: 122331,
        comments_count: 2133,
        area: 'Paris 12'
    },
    {
        id: '12313211233',
        background: '/top-boba-tea-places/desti-nursinta-8cSW5u5U3x4-unsplash.jpg',
        name: 'Hello world!',
        views: 23134,
        likes: 2319,
        comments_count: 332,
        area: 'Paris 12'
    },
    {
        id: '31f0231',
        background: '/top-boba-tea-places/clay-banks-hwLAI5lRhdM-unsplash.jpg',
        name: 'Hello world!',
        views: 23134,
        likes: 2319,
        comments_count: 332,
        area: 'Paris 12'
    }
];

interface HomePageTopBobaTeaPlacesProps {}

export const HomePageTopBobaTeaPlaces: React.FunctionComponent<HomePageTopBobaTeaPlacesProps> = ({}) => (
    // console.log('process.env.PUBLIC_URL', process.env.PUBLIC_URL);

    <section className={BLOCK}>
        <h2>Top Boba Tea Places</h2>
        <div className={`${BLOCK}__grid`}>
            {TOP_BOBA_TEA_PLACES.map((place) => (
                <div
                    className={`${BLOCK}__grid__item`}
                    style={{ backgroundImage: `url(${process.env.PUBLIC_URL}${place.background})` }}
                >
                    <Link to={`/boba-tea/${place.id}`}>
                        <div className={`${BLOCK}__grid__item__content`}>
                            <span className={`${BLOCK}__grid__item__content__title`}>{place.name}</span>
                            <Flex className={`${BLOCK}__grid__item__content__informations`} alignItems="center">
                                <Flex alignItems="center">
                                    <img src={`${process.env.PUBLIC_URL}/icons/eye-white.svg`} alt="eye-white" />
                                    {place.views}
                                </Flex>
                                <Flex alignItems="center">
                                    <img src={`${process.env.PUBLIC_URL}/icons/heart-white.svg`} alt="heart-white" />
                                    {place.likes}
                                </Flex>
                                <Flex alignItems="center">
                                    <img src={`${process.env.PUBLIC_URL}/icons/comment-white.svg`} alt="comment-white" />
                                    {place.comments_count}
                                </Flex>
                                &nbsp;/&nbsp;<span>{place.area}</span>
                            </Flex>
                        </div>
                    </Link>
                </div>
            ))}
        </div>
    </section>
);
