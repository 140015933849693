import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-bootstrap/Carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Header } from '../../components/Header';
import { Footer } from '../../components/Footer';
import { BannerJoinUs } from '../../components/BannerJoinUs';
import { Flex } from '../../components/ui/Flex';
import { RatingStars } from '../../components/ui/RatingStars';

import './about-us-page.scss';

const BLOCK = 'about-us-page';

interface AboutUsProps {}

export const AboutUs: React.FunctionComponent<AboutUsProps> = () => {
    return (
        <>
            <Header />
            <Container className={BLOCK}>
                <Row>
                    <Col xs={12} md={12}>
                        <h1>About Us</h1>
                    </Col>
                </Row>
            </Container>
            <Container className={`${BLOCK} mt-3`}>
                <Row>
                    <Col xs={12} md={12}>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc eget elit lectus. Donec condimentum nunc vehicula dui eleifend rhoncus. In hac habitasse platea dictumst. Ut at rutrum tellus. Nam laoreet posuere eros ac venenatis. Curabitur venenatis ex tempor maximus efficitur. Vestibulum dapibus porta odio, et aliquet velit condimentum vitae. Fusce iaculis id magna id condimentum. Etiam ex magna, venenatis sed metus nec, tempus luctus ipsum.
                        </p>
                        <p>
                            Phasellus tincidunt purus nisi, ac aliquet leo ultricies ut. Phasellus eros est, sollicitudin at pretium ut, imperdiet vitae lectus. Donec vestibulum efficitur rhoncus. Quisque suscipit porttitor justo sed fermentum. Sed quis ante facilisis, volutpat magna a, tincidunt tortor. Quisque sed arcu id elit cursus sollicitudin eget non ligula. Donec iaculis diam eu felis tempus tempus.
                        </p>
                        <p>
                            Duis non porttitor est. Nullam vitae consequat leo, a finibus lorem. Vestibulum dapibus, libero ac gravida eleifend, leo magna imperdiet erat, a ullamcorper est neque eget nulla. Nulla ut accumsan nisl. Duis consequat quis purus eget sodales. Morbi nec est vel est tincidunt vestibulum. Aliquam et lorem et massa facilisis laoreet ut ut dolor. Donec vitae sapien at turpis laoreet condimentum sit amet et ligula. Maecenas elementum tempor nisi, et scelerisque lacus luctus nec. Suspendisse dapibus arcu consectetur ligula imperdiet molestie. Quisque nisi metus, egestas ut est sed, mattis ultricies magna. Nam dignissim urna nec mi laoreet consectetur. Sed justo nisi, convallis quis pharetra id, mollis eget tortor.
                        </p>
                        <p>
                            In scelerisque pulvinar justo, sed euismod tellus sodales id. Suspendisse commodo faucibus quam. In auctor tortor dapibus lacinia molestie. Cras ut metus a leo fringilla suscipit. Vestibulum malesuada elementum aliquet. Maecenas a nibh odio. Nullam a arcu eget dui efficitur tincidunt ut quis nunc. Curabitur consectetur ligula magna. Mauris sollicitudin facilisis dapibus. Vivamus vehicula massa sit amet odio semper, ultrices ultrices eros mollis. Morbi sed nunc sed dui volutpat venenatis ut ut nisl. Nullam mattis eleifend tincidunt.
                        </p>
                        <p>
                            Nunc nec porta felis. Pellentesque vehicula fringilla nunc, et volutpat tortor finibus vitae. Ut feugiat dui et augue condimentum, vitae feugiat nulla elementum. Proin at imperdiet libero. Quisque non porttitor turpis. Mauris scelerisque, metus nec bibendum consectetur, lacus ante vulputate libero, nec pretium diam ipsum nec odio. Nunc pharetra sed dolor vel ultricies. Integer laoreet sem nec molestie suscipit. Vestibulum hendrerit est nec semper gravida. Curabitur sollicitudin vestibulum molestie. Nunc aliquam ac nisl sed cursus. Donec ultrices ligula est, nec laoreet ante venenatis vitae.
                        </p>
                    </Col>
                </Row>
            </Container>
            <BannerJoinUs />
            <Footer />
        </>
    );
};
