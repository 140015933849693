import React from 'react';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import { Flex } from '../ui/Flex';
import { Button } from '../ui/Button';

import './banner-join-us.scss';

const BLOCK = 'banner-join-us';

export const BannerJoinUs: React.FunctionComponent<{}> = () => {
    return (
        <Flex
            isFullWidth={true}
            isFullHeight={true}
            alignItems="center"
            justify="center"
            direction="column"
            className={BLOCK}
        >
            <h2>Are you a Boba Tea?</h2>
            <p>
                Contact us to make your boba tea place visible on our website!
                <br />
                Our visitors will definitely see it and will stop to try your delicious boba teas!
            </p>
            <Button level="secondary" label="Contact us!" className={`${BLOCK}__cta`} />
        </Flex>
    );
};
