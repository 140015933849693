import React from 'react';
import Container from 'react-bootstrap/Container';

import './blog-page.scss';

const BLOCK = 'map-page';

export const BlogPage: React.FunctionComponent<{}> = () => {
    return (
        <div className={BLOCK}>
            <Container>
                Hello, I'm BlogPage
                <span>:D</span>
            </Container>
        </div>
    );
};
