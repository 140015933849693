import React from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';

import { Flex } from '../../../../components/ui/Flex';
import { Button } from '../../../../components/ui/Button';

import './home-page-hero.scss';

const BLOCK = 'home-page-hero';

interface BobaTeaPlace {
    id: string;
    background: string;
    name: string;
    address: string;
    views: number;
    likes: number;
    comments_count: number;
}

const TOP_BOBA_TEA_PLACES = [
    {
        id: '123132',
        background: '/top-boba-tea-places/drink-3131322_1920.jpg',
        name: 'Hello world!',
        address: '23, rue de chateaudun 75009 Paris',
        views: 1000,
        likes: 1231,
        comments_count: 233
    },
    {
        id: '123132asd',
        background: '/top-boba-tea-places/drink-3545791_1920.jpg',
        name: 'Machi Machi!',
        address: '23, rue de chateaudun 75009 Paris',
        views: 32300,
        likes: 1231,
        comments_count: 233
    },
    {
        id: '123132zcxcz',
        background: 'top-boba-tea-places/george-zheng-8h6L01Httpw-unsplash.jpg',
        name: 'Xin fu Tang 1!',
        address: '23, rue de chateaudun 75009 Paris',
        views: 1233230,
        likes: 122331,
        comments_count: 2133
    },
    {
        id: '12313211233',
        background: '/top-boba-tea-places/lisanto-aFnrGfAvdVU-unsplash.jpg',
        name: 'Hello world!',
        address: '23, rue de chateaudun 75009 Paris',
        views: 23134,
        likes: 2319,
        comments_count: 332
    }
];

export const HomePageHero: React.FunctionComponent<{}> = () => {
    const [selectedPlace, setSelectedPlace] = React.useState<BobaTeaPlace | null>(TOP_BOBA_TEA_PLACES[1]);

    return (
        <div className={BLOCK}>
            {selectedPlace ? (
                <div
                    className={`${BLOCK}__hero`}
                    style={{
                        backgroundImage: `url(${process.env.PUBLIC_URL}${selectedPlace.background})`
                    }}
                >
                    <Flex isFullWidth isFullHeight alignItems="center" justify="center">
                        <Flex
                            className={`${BLOCK}__hero__content`}
                            alignItems="center"
                            justify="center"
                            direction="column"
                        >
                            <h2>{selectedPlace.name}</h2>
                            <span>{selectedPlace.address}</span>
                            <Flex isFullWidth alignItems="center" justify="center">
                                <Flex className={`${BLOCK}__hero__content__informations`} alignItems="center">
                                    <img src={`${process.env.PUBLIC_URL}/icons/eye-white.svg`} alt="eye-white" />
                                    {selectedPlace.views}
                                </Flex>
                                <Flex className={`${BLOCK}__hero__content__informations`} alignItems="center">
                                    <img src={`${process.env.PUBLIC_URL}/icons/heart-white.svg`} alt="heart-white" />
                                    {selectedPlace.likes}
                                </Flex>
                                <Flex className={`${BLOCK}__hero__content__informations`} alignItems="center">
                                    <img
                                        src={`${process.env.PUBLIC_URL}/icons/comment-white.svg`}
                                        alt="comment-white"
                                    />
                                    {selectedPlace.comments_count}
                                </Flex>
                            </Flex>
                            <Flex>
                                <Link to={`/boba-tea/${selectedPlace.id}`}>
                                    <Button level="primary" label="See more" />
                                </Link>
                            </Flex>
                        </Flex>
                    </Flex>
                </div>
            ) : (
                <div className={`${BLOCK}__hero`} />
            )}
            <div className={`${BLOCK}__carousel`}>
                {TOP_BOBA_TEA_PLACES.map((place) => {
                    return (
                        <div
                            className={cx(`${BLOCK}__carousel-item`, {
                                [`${BLOCK}__carousel-item--selected`]: selectedPlace && selectedPlace.id === place.id
                            })}
                            style={{ backgroundImage: `url(${process.env.PUBLIC_URL}${place.background})` }}
                            onClick={() => setSelectedPlace(place)}
                        />
                    );
                })}
            </div>
        </div>
    );
};
