import React from 'react';

import { Header } from '../../components/Header';
import { Footer } from '../../components/Footer';
import { BannerJoinUs } from '../../components/BannerJoinUs';
import { HomePageHero } from './components/HomePageHero';
import { HomePageTopBobaTeaPlaces } from './components/HomePageTopBobaTeaPlaces';
import { HomePageLatestDiscoveries } from './components/HomePageLatestDiscoveries';

import './home-page.scss';

const BLOCK = 'home-page';

export const HomePage: React.FunctionComponent<{}> = () => {
    // const result = window.prompt('Password');
    // if (result !== 'helojo') {
    //     return null;
    // }

    return (
        <div className={BLOCK}>
            <Header />
            <HomePageHero />
            <HomePageTopBobaTeaPlaces />
            <HomePageLatestDiscoveries />
            <BannerJoinUs />
            <Footer />
        </div>
    );
};
