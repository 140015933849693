import React from 'react';

import './coming-soon-page.scss';

const BLOCK = 'coming-soon';

export const ComingSoonPage: React.FunctionComponent<{}> = () => {
    return (
        <div className={BLOCK}>
            <div className={`${BLOCK}__coming-soon`}>
                <h1>Where to Boba</h1>
                <h2>Coming soon!</h2>
                <img src="/bubble-tea_color.svg" alt="" />
            </div>
        </div>
    );
};
